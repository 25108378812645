import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class Xero extends React.Component {
	render() {
		const siteTitle = 'Xero'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Xero</h3>
								<br />
								<br />
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/fb6f85fc672faf918a0b94cd6f803178e4cb4c7e/xero.png"
									style={{ width: `100%` }}
									alt="Xero Details"
								/>
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/329906342ce5c38fb8e980db89ec5855164fda22/6_questions_re_xero.png"
									style={{ width: `100%` }}
									alt="Xero Details"
								/>
								<br />
								<br />
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Xero

export const XeroPageQuery = graphql`
	query XeroPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
